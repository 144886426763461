window.addEventListener("load", function () {
  const searchToggle = document.querySelectorAll(".menu-search-toggle");
  const closeSearch = document.querySelectorAll(".close-search");
  const searchContainers = document.querySelectorAll(".search-container");

  searchToggle.forEach((toggle) => {
    toggle.addEventListener("click", function (e) {
      e.preventDefault();
      searchContainers.forEach((container) => {
        container.classList.toggle("search-opened");
      });
    });
  });
  closeSearch.forEach((action) => {
    action.addEventListener("click", function (e) {
      e.preventDefault();
      searchContainers.forEach((container) => {
        container.classList.remove("search-opened");
      });
    });
  });

  const closeNotifications = document.querySelectorAll(".notification-close-button");
  const notifications = document.querySelectorAll(".notification-banner");
  closeNotifications.forEach((closeNotification) => {
    closeNotification.addEventListener("click", function (e) {
      e.preventDefault();
      notifications.forEach((notification) => {
        notification.classList.add("hide");
      });
    }
    );
  });

  
});
