jQuery(function($) {
  // Remove the 'no-js' <body> class
  $("html").removeClass("no-js");

  // Each card heading needs to have smaller top percentage for taller headings. Evens out. CSS fallback works fairly well enough.
  $(".card-flipper h4").each(function() {
    var $this = $(this);
    var low = 70 - ($this.height() / 10) * ($this.height() / 10 / 2);
    var $top = low / 2;
    $this.css("top", $top + "%");
  });

  // Partners slider, shows up on pages with slider enabled
  $(".partners-slider").slick({
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    draggable: true,
    autoplay: true,
    appendArrows: $(".partners-arrows-container"),
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  });

  // WYSIWYG Slide repeater shows up on pages with sliders created
  let wACIndex = 0;
  $(".heading-arrow-container__wysiwyg-slider-arrows-container").each(
    function() {
      wACIndex++;
      $(this).addClass("wysiwyg-arrow-container-" + wACIndex);
    }
  );

  let wPArrowIndex = 0;
  $(".sb-slick-prev-wysiwyg").each(function() {
    wPArrowIndex++;
    $(this).addClass("wysiwyg-prev-arrow-" + wPArrowIndex);
  });

  let wNArrowIndex = 0;
  $(".sb-slick-next-wysiwyg").each(function() {
    wNArrowIndex++;
    $(this).addClass("wysiwyg-next-arrow-" + wNArrowIndex);
  });

  let wSliderIndex = 0;
  $(".wysiwyg-slide-repeater").each(function() {
    wSliderIndex++;

    $(this)
      .addClass("wysiwyg-slider-" + wSliderIndex)
      .slick({
        slidesToShow: 3,
        infinite: true,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        draggable: true,
        prevArrow: $(
          ".sb-slick-prev-wysiwyg.wysiwyg-prev-arrow-" + wSliderIndex
        ),
        nextArrow: $(
          ".sb-slick-next-wysiwyg.wysiwyg-next-arrow-" + wSliderIndex
        ),
        appendArrows: $(
          ".heading-arrow-container__wysiwyg-slider-arrows-container.wysiwyg-arrow-container-" +
            wSliderIndex
        ),
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
            },
          },
        ],
      });
  });

  // Image Slide repeater shows up on pages with sliders created
  let isACIndex = 0;
  $(".heading-arrow-container__image-slider-arrows-container").each(function() {
    isACIndex++;
    $(this).addClass("image-arrow-container-" + isACIndex);
  });

  let isPArrowIndex = 0;
  $(".sb-slick-prev-image").each(function() {
    isPArrowIndex++;
    $(this).addClass("image-prev-arrow-" + isPArrowIndex);
  });

  let isNArrowIndex = 0;
  $(".sb-slick-next-image").each(function() {
    isNArrowIndex++;
    $(this).addClass("image-next-arrow-" + isNArrowIndex);
  });

  var iSliderIndex = 0;
  $(".image-slide-repeater").each(function() {
    iSliderIndex++;

    $(this)
      .addClass("image-slider-" + iSliderIndex)
      .slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        draggable: true,
        prevArrow: $(".sb-slick-prev-image.image-prev-arrow-" + iSliderIndex),
        nextArrow: $(".sb-slick-next-image.image-next-arrow-" + iSliderIndex),
        appendArrows: $(
          ".heading-arrow-container__wysiwyg-slider-arrows-container.image-arrow-container-" +
            iSliderIndex
        ),
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
            },
          },
        ],
      });
  });

  //TODO: Seth, please figure out the weird logic rabbithole that is this cookie. Thank you.
  let notification = $("#close-notification");

  notification.on("click", function(e) {
    document.cookie = "hide-notification=true";
    $(".notification-widget").removeClass("hide-notification");
  });

  if (!document.cookie.indexOf("hide-notification=true") > -1) {
    $(".notification-widget").addClass("show-notification");
  }

  $(".two-column-container__page-hero").stick_in_parent();
});
