const Flickity = require("flickity");

window.addEventListener("load", function() {
  var elem = document.querySelector(".main-carousel");
  var flkty = new Flickity(elem, {
    // options
    cellAlign: "left",
    contain: true,
    prevNextButtons: false,
    pageDots: true,
    autoPlay: 5000,
  });
});
